import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login1',
    component: () => import('../auth/login.vue')
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../auth/login.vue')
  }, {
    path: '/logout',
    name: 'logout',
    component: () => import('../auth/logout.vue')
  }, {
    path: '/respass/:id',
    name: 'respass',
    component: () => import('../auth/password-resed.vue')
  }, {
    path: '/research',
    name: 'research',
    component: () => import('../views/Research/Research.vue')
  }, {
    //Профиль залогиненного в текущий момент пользователя.
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile/Profile.vue')
  }, {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Users.vue')
  }, {
    path: '/empls',
    name: 'empls',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Empls.vue')
  }, {
    path: '/roles',
    name: 'roles',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Roles.vue')
  }, {
    path: '/survey',
    name: 'survey',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Survey/SurveyAdmin.vue')
  }, {
    path: '/authlogs',
    name: 'authlogs',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/AuthLogs.vue')
  }, {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/User.vue')
  }, {
    path: '/empl',
    name: 'empl',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Empl.vue')
  }, {
    path: '/role',
    name: 'role',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Role.vue')
  }, {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "*",
    name: '404',
    component: () => import('../views/404.vue')
  }

  //   path: '/password-change',
  //   name: 'password-change',
  //   component: () => import('../views/Account/PasswordChange.vue')
  // }, {
  //   path: '/mh',
  //   name: 'messageHistory',
  //   component: () => import('../components/message/message-history.vue')
  // }
]

const router = new VueRouter({
  routes
});

/* eslint-disable-next-line no-unused-vars */
// router.beforeEach((to, from, next) => {
//   let guiIsComponentChanged = store.getters['guiIsComponentChanged'];
//   if(guiIsComponentChanged) {
//     store.commit('setGuiCanceledToRouteName', to.name);
//     store.commit('setGuiCanceledFromRouteName', from.name);
//     // next(false); //INFO: вызывает ошибку Uncaught (in promise)...
//     return false;   //INFO: из за этого пришлось добавить setGuiCanceledFromRouteName, что бы отслеживать откуда собирались уйти
//   } else {
//     next()
//   }
// });

export default router;
