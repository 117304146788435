import store from '@/store';
// import logger from './logger';

export default {
    analyseResponseAndCB(response, cb, returnedField) {
        // console.log('analyseResponseAndCB', returnedField, response.data);
        if(response.data) {
            if(!response.data.isSuccess) {
                store.commit('addMessages', response.data.messages);
                if(returnedField === 'full-response') {
                    cb(response.data);
                } else {
                    cb(null);
                }
            } else {
                if(returnedField === 'full-response') {
                    return cb(response.data);
                }
                if(!returnedField) {
                    returnedField = 'data';
                }
                cb(response.data[returnedField]);
            }
        } else {
            store.commit('addMessageError', 'Невозможно получить данные с сервера');
            cb(null);
        }
    }
};