import { Notification } from 'element-ui';

function showOne(message) {
    if(message) {
        //INFO: setTimeout - костыль! без него прилетевшие одновременно сообщения накладываются друг на друга
        setTimeout(() => { 
            Notification({
                // title: message.type,
                dangerouslyUseHTMLString: true,
                message: message.msg,
                type: message.type,
                position: 'bottom-right',
                customClass: 'notification-custom-class'
            }); 
        }, 100);
    }
}

let state = {
    messages: [],
    lastTime: 0
};

const getters = {
    messageList: state => state.messages
};

const mutations = {
    addMessages(state, messages) {
        if(Array.isArray(messages)) {
            const len = messages.length;
            for(let i = 0; i < len; i++) {
                if(messages[i] && messages[i].msg) {
                    if(messages[i].type) {
                        messages[i].type = messages[i].type.toLowerCase();
                    }
                    showOne(messages[i]);
                    state.messages.push(messages[i]);
                } 
            }
        } else {
            if(messages && messages.msg) {
                if(messages.type) {
                    messages.type = messages.type.toLowerCase();
                }
                state.messages.push(messages);
                showOne(messages);
            } 
        }
    },
    addMessageInfo(state, msg) {
        showOne({ msg: msg, type: 'info' });
        state.messages.push({ msg: msg, type: 'info' });
    },
    addMessageError(state, msg) {
        showOne({ msg: msg, type: 'error' });
        state.messages.push({ msg: msg, type: 'error' });
    },
    addMessageWarning(state, msg) {
        showOne({ msg: msg, type: 'warning' });
        state.messages.push({ msg: msg, type: 'warning' });
    }
};

export default {
    state,
    getters,
    mutations
}