import Vue from 'vue';
import moment from 'moment';

const ONE_DAY_MSEC = 1000 * 60 * 60 * 24;

export default {
    dateFormat: Vue.filter('dateFormat', function(value, isDateOnly, formatStr) {
        if(value) {
            if(formatStr) {
                return moment(value).format(formatStr);
            }
            if(isDateOnly) {
                return moment(value).format('DD.MM.YYYY');
            } else {
                return moment(value).format('DD.MM.YYYY HH:mm:ss');
            }
        } else {
            return '';
        }
    }),
    dateInterval: Vue.filter('dateInterval', function(value, maxNegative) {
        if(value) {
            if(moment(value).isValid()) {
                let interval = (new Date(value) - new Date()) / ONE_DAY_MSEC;
                interval = Math.round(interval);
                if(maxNegative) {
                    if(interval < maxNegative) {
                        return maxNegative;
                    } else {
                        return interval;
                    }
                } else {
                    return interval;
                }
            } else {
                return null;
            }

        } else {
            return null;
        }
    })
}