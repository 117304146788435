<template>
    <el-dialog
        :visible.sync="isShowCompanieTreeDialog"
        width="527px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false">
        <div class="comp-sel-dialog">
          <div class="chpwd-dialog-title">{{$t('labels.company')}}</div>
          <div v-loading="isLoading" class="comp-sel-control">
            <el-tree 
              ref="companyTry"
              :data="compTree" 
              :props="defaultProps" 
              node-key="id" 
              :default-expanded-keys="[compSelected.id]"
              :current-node-key="compSelected.id"
              :accordion="true"
              :highlight-current="true"
              @node-click="handleNodeClick" >
            </el-tree>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button round class="del-dialog-cancel" @click="closeDialog">{{$t('buttons.cancel')}}</el-button>
            <el-button round class="del-dialog-cancel" @click="clearCompany">{{$t('buttons.notSelected')}}</el-button>
            <el-button round type="primary" @click="selectCompany" :disabled="!compSelected || !compSelected.id || compSelected.id == '00'">{{$t('buttons.applay')}}</el-button>
        </div>
    </el-dialog>
</template>

<script>
import serverDict from '../api/server-dict';
import utilites from '../lib/utilites';

export default {
  props: ['isShowCompanieTreeDialog', 'company'],
  data() {
    return {
        isLoading: false,
        defaultProps: {
            children: 'children',
            label: 'title'
        },
        compTree: [],
        compSelected: this.company ? this.company: {}
    }
  },
  methods: {
      openDialogSelectCompany() {
        //INFO: IMPORTANT: что бы не орал element-ui.tree!!!
        if(!this.compSelected.id) {
          this.compSelected.id = '00'
        }

        this.isShowCompanieTreeDialog = true;
        if(this.compTree.length == 0) {
            this.isLoading = true;
            serverDict.companiesTree(3, data => {
                this.isLoading = false;
                if(data && data[0]) {
                    utilites.dbCompatiesToTree(this.compTree, data, 1);
                }
            });
        }
      },
      closeDialog() {
        this.$emit('event-select-company-dialog-cancel');
      },
      selectCompany() {
          this.$emit('event-select-company-dialog-select', this.compSelected);
      },
      clearCompany() {
        this.compTree = [];
        this.compSelected = {};
        this.$emit('event-select-company-dialog-clear');
      },
      handleNodeClick(data) {
        this.compSelected = data;
      },
  },
  watch: {
      'isShowCompanieTreeDialog': {
          handler: function (val) {
            if(val) this.openDialogSelectCompany();
          }
      }
  }

}
</script>


<style scoped>
.chpwd-dialog-title {
  height: 29px;
  font-size: 30px;
  font-weight: 300;
  text-align: left;
  margin: -20px 0 46px 34px;
  color: #25272f;
}
.comp-sel-dialog {
  height: 480px;
}
.comp-sel-control {
  height: 440px;
  overflow-y: auto;
}
</style>