export const SERVER_BASE_URL = !process.env.VUE_APP_SERVER_BASE_URL ? 'http://localhost:7007' : process.env.VUE_APP_SERVER_BASE_URL;
export const SINGLE_LANGUAGE_NAME = process.env.VUE_APP_SINGLE_LANGUAGE_NAME ? process.env.VUE_APP_SINGLE_LANGUAGE_NAME : false;

export const SERVER_ATTACH_URL = '/attach/showImage?imgId=';
export const SERVER_ATTACH_UPLOAD_URL = '/attach/upload';
export const SERVER_ATTACH_SEARCH_URL = '/attach/search?t=';
export const IS_LOGGER_ENABLED = false;

export const TOKEN_LOCALSTORAGE_NAME = 'geo-app-token';
export const LOCALE_LOCALSTORAGE_NAME = 'geo-app-locale';

export const DEFAULT_ROUTE = 'research';

export const DB_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const ROLE_ADMIN = '2107c161-ca21-11eb-b8d2-c1fb6f8b932f';

export const PROTOCOL_TYPE_TNK = 1;

export const EVENT_NEW_DICT_ITEM_CREATED = 'new-dict-item-created';

export const SERVER_SEARCH_LIMIT = 20;
