import Vue from 'vue';
import * as constants from '../config/constants';
import serverHelpers from './server-helpers';
import logger from './logger';

export default {
    typeahedByModel(modelName, searchStr, cb, addParams) {
        if(!addParams) {
            addParams = '';
        }
        Vue.http.get(constants.SERVER_BASE_URL + `/${modelName}/typeahead?str=${searchStr}${addParams}`).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
                logger.writeError('server-model typeahedByModel', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    },
    typeahedCi(md, cb) {
        Vue.http.post(constants.SERVER_BASE_URL + '/aih/ciTypeahead', md).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
                logger.writeError('server-model typeahedCi', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    },
    showByModel(modelName, id, cb) {
        Vue.http.get(constants.SERVER_BASE_URL + `/${modelName}/show?id=${id}`).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
                logger.writeError('server-model showByModel', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    },
    searchByModel(modelName, model, cb) {
        Vue.http.post(constants.SERVER_BASE_URL + `/${modelName}/search`, model).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
                logger.writeError('server-model searchByModel', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    },
    saveModel(modelName, model, cb, whatFieldReturn) {
        Vue.http.post(constants.SERVER_BASE_URL + `/${modelName}/save`, model).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, whatFieldReturn);
        }, err => {
                logger.writeError('server-model saveModel', err);
                serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
    deleteModel(modelName, model, cb) {
        Vue.http.post(constants.SERVER_BASE_URL + `/${modelName}/delete`, model).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'isSuccess');
        }, err => {
            logger.writeError('server-model saveModel', err);
            serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
    modelSpecific(modelName, md, cb, whatFieldReturn) {
        Vue.http.post(constants.SERVER_BASE_URL + `/${modelName}/modelSpecific`, md).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, whatFieldReturn);
        }, err => {
                logger.writeError('server-model modelSpecific', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    },
    importTnk(fileId, cb) {
        Vue.http.get(constants.SERVER_BASE_URL + `/tnk-upload/importTnk?fileId=${fileId}`).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'isSuccess');
        }, err => {
                logger.writeError('server-model importTnk', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'isSuccess');
        });
    },
    importTnkGetProgress(cb) {
        Vue.http.get(constants.SERVER_BASE_URL + '/tnk-upload/getImportProgress').then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
                logger.writeError('server-model importTnkGetProgress', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    },
    exportTnk(md, cb) {
        Vue.http.post(constants.SERVER_BASE_URL + '/tnk-export/exportTnk', md).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
                logger.writeError('server-model exportTnk', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    }
}