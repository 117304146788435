import Vue from 'vue'
import Vuex from 'vuex'

import message from '../components/message/message-store';
import dict from './modules/dictionary-store';
import currentUser from './modules/current-user-store';
import app from './modules/app-store';
import syrveyStore from '../views/Survey/SurveyStore';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    message,
    dict,
    currentUser,
    app,
    syrveyStore
  }
})

