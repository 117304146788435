import Vue from 'vue';
import * as constants from '../config/constants';
import serverHelpers from './server-helpers';
import logger from './logger';

export default {
    retriveAllRoles(cb) {
        Vue.http.get(constants.SERVER_BASE_URL + '/dict/roles').then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb);
        }, err => {
            logger.writeError('server-dicts retriveAllRoles', err);
            serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
    retriveAllTypes(cb) {
        Vue.http.get(constants.SERVER_BASE_URL + '/dict/allTypes').then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb);
        }, err => {
            logger.writeError('server-dicts retriveAllTypes', err);
            serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
    create(md, cb) {
        Vue.http.post(constants.SERVER_BASE_URL + '/dictItem/create', md).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb);
        }, err => {
            logger.writeError('server-dicts create', err);
            serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
    typeahead(str, dictTypeId, cb) {
        Vue.http.get(constants.SERVER_BASE_URL + `/dictItem/typeahead?str=${str}&dtId=${dictTypeId}`).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
            logger.writeError('server-dicts typeahead', err);
            serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
    modelSpecific(md, cb) {
        Vue.http.post(constants.SERVER_BASE_URL + '/dictItem/modelSpecific', md).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb);
        }, err => {
            logger.writeError('server-dicts modelSpecific', err);
            serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
    companiesTree(lvl, cb) {
        let url = constants.SERVER_BASE_URL + '/dict/companiesTree';
        if(lvl) { 
            url += '?lvl=' + lvl;
        }
        Vue.http.get(url).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
            logger.writeError('server-dicts dictImport', err);
            serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    }
}