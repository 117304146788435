import store from '@/store';
import router from '@/router';
import i18n from '../i18n'

export default {
    checkPassword(pass, isEnabledEmptyPass, isShowMessageWhatever) {
        if(!pass) {
            if(isEnabledEmptyPass) {
                return true;
            } else {
                pass = ''; //INFO: что бы не возникало NPE
            }
        }

        let msg = i18n.t('passChange.pass1');
        let isWrong = false;

        if(pass.length < 8) {
            msg += '<span class="object-active-warning">' + i18n.t('passChange.pass2') + '</span>';
            isWrong = true;
        } else {
            msg += i18n.t('passChange.pass2');
        }

        if(pass.search(/[0-9]/) == -1 || pass.search(/[a-zA-Z]/) == -1) {
            msg += '<span class="object-active-warning">' + i18n.t('passChange.pass3') + '</span>';
            isWrong = true;
        } else {
            msg += i18n.t('passChange.pass3');
        }
        if(pass.search(/[a-z]/) == -1 || pass.search(/[A-Z]/) == -1) {
            msg += '<span class="object-active-warning">' + i18n.t('passChange.pass4') + '</span>';
            isWrong = true;
        } else {
            msg += i18n.t('passChange.pass4');
        }

        if(isWrong) {
            store.commit('addMessageError', msg);
        } else if(isShowMessageWhatever) {
            store.commit('addMessageInfo', msg);
        }

        return !isWrong;
    },
    companyConvertImage(company) {
        if(company && company.logo && company.logo.data) {
            try {
                return String.fromCharCode(...company.logo.data);
            } catch {
                store.commit('addMessageError', 'Ошибка при конвертации изображения. Изображение слишком большое.');
                return '';
            }
        }
        return '';
    },
    tryToGoToRoute(route) {
        let guiIsComponentChanged = store.getters['guiIsComponentChanged'];
        if(guiIsComponentChanged) { 
            store.commit('setGuiToRouteName', route);
        } else {
            this.goToRoute(route);
        }
    },
    goToRoute(route) {
        if(route == -1) {
            router.go(-1);
        } else {
            router.push(route);
        }
    },

    /**
     * Формирование данных для element-ui.tree.
     * @param {Array} ct - массив, котрой будет наполняться данными для отрисовки дерева
     * @param {Array} cs - данные, которы прилетели с сервера
     * @param {Number} lvl - номер уровня, с которого начинается построение дерева. при первом вызове всегда должен быть 1, потом будет увеличиваться сам. 
     */
    dbCompatiesToTree(ct, cs, lvl) {
        let isExist = false;
        cs.forEach(el => {
            if(el.lvl == lvl) {
                isExist = true;
                if(lvl == 1) {
                    el.children = [];
                    ct.push(el)
                } else {
                    this.findParent(cs, el);
                }
            }
        })
        if(isExist) {
            this.dbCompatiesToTree(ct, cs, ++lvl);
        }
    },
    findParent(cs, toAdd) {
        let f;
        if(cs) {
            f = cs.find(el => {
                return el.id == toAdd.parent_id;
            });
            if(f) {
                if(Array.isArray(f.children)) {
                    f.children.push(toAdd);
                } else {
                    f.children = [];
                    f.children.push(toAdd);
                }
            } else {
                this.findParent(cs.children, toAdd);
            }
        }
    }
}