import Vue from 'vue';
import * as constants from '../config/constants';
import serverHelpers from './server-helpers';
import logger from './logger';

export default {
    accountTypeahead(str, cb) {
        Vue.http.get(constants.SERVER_BASE_URL + '/account/typeahead?str=%' + str).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
                logger.writeError('accountTypeahead', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    },
    show(id, cb) {
        Vue.http.get(constants.SERVER_BASE_URL + '/account/show?id=' + id).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
                logger.writeError('accountTypeahead', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    },
    showWithDicts(id, cb) {
        Vue.http.get(constants.SERVER_BASE_URL + '/account/modelSpecific?method=showWithDicts&id=' + id).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
                logger.writeError('accountTypeahead', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    },
    create(account, cb) {
        Vue.http.post(constants.SERVER_BASE_URL + '/account/create', account).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb);
        }, err => {
                logger.writeError('create account', err);
                serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
    update(account, cb) {
        Vue.http.post(constants.SERVER_BASE_URL + '/account/update', account).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'isSuccess');
        }, err => {
                logger.writeError('update account', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'isSuccess');
        });
    },
    updatePassword(passwords, cb) {
        let md = {
            method: 'updatePassword',
            passwords: passwords
        }
        Vue.http.post(constants.SERVER_BASE_URL + '/account/modelSpecific', md).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'isSuccess');
        }, err => {
                logger.writeError('updatePassword', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'isSuccess');
        });
    },
    resetPassword(userId, newPass, cb) {
        let md = {
            method: 'resetPassword',
            userId: userId,
            newPass: newPass
        }
        Vue.http.post(constants.SERVER_BASE_URL + '/account/modelSpecific', md).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'isSuccess');
        }, err => {
                logger.writeError('resetPassword', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'isSuccess');
        });
    },
    showAllByRole(roleId, offset, cb) {
        let md = {
            method: 'showAllByRole',
            roleId: roleId,
            offset: offset
        }
        Vue.http.post(constants.SERVER_BASE_URL + '/account/modelSpecific', md).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'data');
        }, err => {
                logger.writeError('showAllByRole', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'data');
        });
    }
}