
<template>
  <div v-bind:style="{width: componentWidth}" class="component-item">
    <div :class="{'label-group': inputType != 'company', 'label-company': inputType == 'company'}">
      <div class="component-label">{{title}}</div> <div v-show="isRequired" class="required-star">*</div>
    </div>
    <input v-if="inputType != 'company' && inputType != 'phone' && inputType != 'password'" :type="inputType" 
      v-model="content" @input="handleInput" @change="handleChange" :disabled="isDisabled"
      class="component-input" :class="{ 'required-missed': isMassedRequired || isWrong }" autocomplete="off">

    <MazPhoneNumberInput v-if="inputType == 'phone'" @change="handleChange" @update="handlePhoneInput"
      v-model="content"
      :translations="{ countrySelectorLabel: $t('phoneComponent.code'), phoneNumberLabel: '', example: $t('phoneComponent.example')}"
    />
    <!-- <input v-if="inputType == 'phone'" type="text" 
      v-model="content" @input="handleInput" @change="handleChange" :disabled="isDisabled"
      class="component-input" :class="{ 'required-missed': isMassedRequired || isWrong }" autocomplete="off"> -->

    <div v-if="inputType == 'password'" style="border: solid 1px #c5cace; border-radius: 6px;" :class="{ 'required-missed': isWrong }">
      <input type="password" v-show="!isShowPass"
        style="border: none; margin-top: 0px"
        v-bind:style="{width: 'calc('+componentWidth+' - 100px)'}"
        v-model="content" @input="handleInput" @change="handleChange" 
        class="component-input" autocomplete="off">
      <span class="zzz" v-show="!isShowPass" @click="changeShowPass">{{$t('buttons.show')}}</span>
      <input type="text" v-show="isShowPass"
        style="border: none; margin-top: 0px"
        v-bind:style="{width: 'calc('+componentWidth+' - 100px)'}"
        v-model="content" @input="handleInput" @change="handleChange" 
        class="component-input" autocomplete="off">
      <span class="zzz" v-show="isShowPass" @click="changeShowPass">{{$t('buttons.hide')}}</span>
    </div>

    <div v-if="inputType == 'company'">
      <el-row>
        <el-col :span="14"><div class="selected-company">{{compSelected.title}}</div></el-col>
        <el-col :span="10">
          <div class="company-logo" :style="{'background-image': 'url('+logo+')'}"></div>

          <div v-show="!isHidden && isDisabled" class="company-select-button button-as-text button-as-text-disabled">{{$t('buttons.select')}}</div>
          <div v-show="!isHidden && !isDisabled" class="company-select-button button-as-text" @click="isShowCompanieTreeDialog = true">{{$t('buttons.select')}}</div>
        </el-col>
      </el-row>
    </div>

    <CompanySelectDialog :isShowCompanieTreeDialog="isShowCompanieTreeDialog" :company="compSelected"
      @event-select-company-dialog-cancel="isShowCompanieTreeDialog = false"
      @event-select-company-dialog-select="selectCompany"
      @event-select-company-dialog-clear="clearCompany"></CompanySelectDialog>

  </div>
</template>

<script>
import { MazPhoneNumberInput } from 'maz-ui';

import utilites from '../lib/utilites';
import CompanySelectDialog from './CompanySelectDialog.vue';

export default {
  props: ['value', 'cw', 'title', 'isRequired', 't', 'isWrong', 'isDisabled', 'isHidden', 'company', 'maxLen'],
  computed: {
      isMassedRequired() {
          return this.isRequired && !this.content
      }
  },
  data() {
      return {
          content: this.value,
          componentWidth: this.cw ? this.cw : '500px',
          inputType: this.t && this.t != 'number' ? this.t : 'text',
          isShowPass: false,
          isLoading: false,
          //выбор компаний
          isShowCompanieTreeDialog: false,
          compSelected: this.company ? this.company: {},
          logo: utilites.companyConvertImage(this.company),
      }
  },
  methods: {
      //TODO: сделать проверку на обязательные поля
      handleInput() {
        if(this.t == 'number') {
          this.content = this.content.replace(/\D/g,'');
        }
        if(this.maxLen && this.content.length > this.maxLen) {
          this.content = this.content.substring(0, this.maxLen);
        }
        this.$emit('update:value', this.content);
      },
      handlePhoneInput(phone) {
        if(this.content && this.content.formatInternational) {
          this.$emit('update:value', this.content.formatInternational);
        } else {
          if(phone && phone.formatInternational) {
            this.$emit('update:value', phone.formatInternational);
          } else {
            this.$emit('update:value', '');
          }
        }
        
      },
      handleChange() {
        this.$store.commit('setGuiIsComponentChanged', true);
      },
      selectCompany(company) {
          this.compSelected = company;
          this.$emit('update:value', this.compSelected.id);
          this.logo = utilites.companyConvertImage(this.compSelected);
          this.$store.commit('setGuiIsComponentChanged', true);
          this.isShowCompanieTreeDialog = false;
      },
      clearCompany() {
        this.compSelected = {};
        this.$emit('update:value', null);
        this.logo = null;
        this.$store.commit('setGuiIsComponentChanged', true);
        this.isShowCompanieTreeDialog = false;
      },
      changeShowPass() {
          this.isShowPass = !this.isShowPass;
      }
  },
  components: {
      MazPhoneNumberInput, CompanySelectDialog
  }
}
</script>

<style scoped>
.component-item {
  height: 100%;
  float: left;
  margin: 16px 24px;
}
.label-group {
  height: 24px;
  /* border: 1px solid red; */
}
.label-company {
  width: 120px;
  height: 24px;
  /* border: 1px solid lightcoral; */
}
.component-label {
  height: 24px;
  width: 80%;
  margin-left: 13px; 
  vertical-align: top;
  font-size: 16px;
  text-align: left;
  color: #828589;
  display:inline;
  /* border: 1px solid green; */
}
.required-star {
  height: 10px;
  width: 10px;
  font-size: 24px;
  color: #5f74f5;
  display:inline;
  /* border: 1px solid blue; */
}

.required-missed {
  border: solid 1px #f24841 !important;
}

.component-input {
  height: 20px;
  width: 100%;
  outline: none;
  margin-top: 10px;
  padding: 12px;
  border-radius: 6px;
  border: solid 1px #c5cace;
  font-size: 16px;
  letter-spacing: normal;
  text-align: left;
  color: #31343d;
}

.company-select-button {
  width: 100%;
  height: 18px;
  margin-top: 6px;
  text-align: end;
  /* border: 1px solid green; */
}
.selected-company {
  width: 100%;
  height: 18px;
  margin: 30px 0 18px 13px;
  float: left;
  white-space: nowrap!important;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: 1px solid greenyellow; */
}
.zzz {
  color: #5f74f5;
  cursor: pointer;
}

.company-logo {
  width: 100%;
  margin-top: -30px;
  height: 52px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  /* border: 1px solid hotpink; */
}
</style>
