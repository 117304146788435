import Vue from "vue";
import { LOCALE_LOCALSTORAGE_NAME, SINGLE_LANGUAGE_NAME } from './config/constants';

//INFO: ElementUI setup
import ElementUI from 'element-ui';
import localeRu from 'element-ui/lib/locale/lang/ru-RU';
import localeEn from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import '../element-variables.scss'


function setElementUiLocale() {
    let localeName = this.getLocaleName();
    let locale = localeName == 'en' ? localeEn : localeRu;
    Vue.use(ElementUI, { locale });
}

function getLocaleName() {
    if(SINGLE_LANGUAGE_NAME) return SINGLE_LANGUAGE_NAME;
    const locale = window.localStorage.getItem(LOCALE_LOCALSTORAGE_NAME);
    if(locale) return locale;
    return 'ru'
}

export default {
    setElementUiLocale: setElementUiLocale,
    getLocaleName: getLocaleName
}