<template>
    <div id="app">
        <div v-if="currentUser">
            <img src="@/assets/img/header/lkz_ico_6.svg" alt="" class="logo">
            <div class="Rounded-Rectangle-1160">
                <div style="height: 36px;"></div>
                <img src="@/assets/img/menu/menuico_01.svg" alt="" class="menu-button clickable" 
                    v-bind:class="{'menu-button-active': activeMenu=='research'}" @click="goToRoute('research')">
                <div style="height: 8px;"></div>
                <!-- <img src="@/assets/img/menu/menuico_02.svg" alt="" class="menu-button disabled" 
                    v-bind:class="{'menu-button-active': activeMenu==2}"> -->
                <div style="height: 46px;"></div>
                <!-- <img src="@/assets/img/menu/menuico_03.svg" alt="" class="menu-button disabled" 
                    v-bind:class="{'menu-button-active': activeMenu==3}"> -->
                <div style="height: 8px;" v-if="!LNG_NAME"></div>
                <div class="menu-button clickable" v-if="!LNG_NAME"> 
                    <img v-show="$i18n.locale == 'ru'" src="@/assets/img/menu/flag_ru.svg" alt="" height="36px" width="36px" style="padding: 10px;" @click="isShowLngDialog=!isShowLngDialog">
                    <img v-show="$i18n.locale == 'en'" src="@/assets/img/menu/flag_en.svg" alt="" height="36px" width="36px" style="padding: 10px;" @click="isShowLngDialog=!isShowLngDialog">
                </div>
                <div style="height: 8px;"></div>
                <img src="@/assets/img/menu/menuico_04.svg" alt="" class="menu-button clickable" 
                    v-bind:class="{'menu-button-active': activeMenu=='profile'}"  @click="goToRoute('profile')">
                <div v-show="isAdmin" style="height: 46px;"></div>
                <img v-show="isAdmin" src="@/assets/img/menu/menuico_06.svg" alt="" class="menu-button clickable" 
                    v-bind:class="{'menu-button-active': activeMenu=='users'}"  @click="goToRoute('users')">
                <div style="height: 8px;"></div>
                <img src="@/assets/img/menu/menuico_05.svg" alt="" class="menu-button clickable"
                        @click="isShowDialog = true">
                <div style="height: 26px;"></div>
            </div>

            <div v-if="currentUser.is_password_rotten"><password-change></password-change></div>
        </div>

        <div v-show="isShowLngDialog" class="lng-dialog">
            <div class="lng-title" v-bind:class="{ 'lng-active': $i18n.locale == 'ru' }" @click="changeLng('ru')">Русский</div>
            <div class="lng-title" v-bind:class="{ 'lng-active': $i18n.locale == 'en' }" @click="changeLng('en')">English</div>
        </div>

        <el-dialog
            :visible.sync="isShowDialog"
            width="527px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            center>
            <img src="@/assets/img/lkz_ico_46.svg" alt="exit" class="del-dialog-img">
            <div class="del-dialog-title">{{$t('buttons.logoutQuestion')}}</div>
            <div slot="footer" class="dialog-footer">
                <el-button round class="del-dialog-cancel" @click="isShowDialog = false">{{$t('buttons.cancel')}}</el-button>
                <el-button type="primary" round @click="isShowDialog = false; goToRoute('logout')">{{$t('buttons.logoutYes')}}</el-button>
            </div>
        </el-dialog>

        <div class="main-content">
            <router-view/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PasswordChange from './components/PasswordChange';
import utilites from './lib/utilites';
import {DEFAULT_ROUTE, SINGLE_LANGUAGE_NAME, LOCALE_LOCALSTORAGE_NAME} from './config/constants';

export default {
    computed: mapGetters(['currentUser', 'currentRoles', 'isAdmin']),
    data() {
        return {
            activeMenu: '',
            isShowDialog: false,
            isShowLngDialog: false,
            LNG_NAME: SINGLE_LANGUAGE_NAME
        }
    },
    methods: {
        setDefaultIndex() {
            this.changeLng(SINGLE_LANGUAGE_NAME);
            if(this.$router && this.$router.currentRoute) {
                //console.log(this.$router.currentRoute)
                //this.$store.dispatch('clearCurrentUserInfo');
                if(this.$router.currentRoute.name == 'respass') {
                    this.$store.dispatch('clearCurrentUserInfo');
                } else if(this.$router.currentRoute.name != DEFAULT_ROUTE && this.$router.currentRoute.name != 'profile' && this.$router.currentRoute.name != 'user') {
                    if(this.$router.currentRoute.path && this.$router.currentRoute.path.endsWith('/')) {
                        this.goToRoute('/'+DEFAULT_ROUTE);
                    } else {
                        this.goToRoute(DEFAULT_ROUTE);
                    }
                }
            } else {
                this.goToRoute(DEFAULT_ROUTE);
            }  
        },
        goToRoute(key) {
            this.isShowLngDialog = false;
            if (this.activeMenu != key) {
                utilites.tryToGoToRoute(key);
            }
        },
        changeLng(lng) {
            if(lng) {
                localStorage.setItem(LOCALE_LOCALSTORAGE_NAME, lng);
                this.$i18n.locale = lng;
                this.isShowLngDialog = false;
            }
        }
    },
    created() {
        this.$store.dispatch('retriveCurrentUserInfo');
        //INFO: если retriveAllDicts прилетит раньше, чем 
        //  retriveCurrentUserInfo то будет ошибка при построении меню... запрашиваем в вотче, что бы избежать этой  ситуации
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState == 'visible' && this.currentUser) {
                this.$store.dispatch('retrieveNewSurveyCount');
            }
            if (document.visibilityState == 'hidden') {
                this.$store.dispatch('stopRetrievingNewSurveyCount');
            }
        });
    },
    components: {
        'password-change': PasswordChange
    },
    watch: {
        currentUser: {
            handler: function (val) {
                if(val) {
                    this.setDefaultIndex();
                    this.$store.dispatch('retriveAllDicts');
                }
            }
        },
        '$route': {
            handler: function(to) {
                if(to && to.name) {
                    if(to.name == 'research' || to.name == 'users') {
                        this.$store.dispatch('retrieveNewSurveyCount');
                    } else if(to.name == 'profile' || to.name == 'login') {
                        //INFO: на вкладке профиля колокольчик с уведомлениями о новых опросах не отображается, прекращаем запросы 
                        this.$store.dispatch('stopRetrievingNewSurveyCount');
                    }

                    if(to.name == 'user' || to.name == 'empl' || to.name == 'role' || to.name == 'empls' || to.name == 'roles') {
                        this.activeMenu = 'users';
                    } else {
                        this.activeMenu = to.name;
                    }
                }
                
                // 
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style>
@import './assets/style/main.css';
.Rounded-Rectangle-1160 {
    width: 70px;
    /* height: 490px; */
    /* сверху | справа | снизу | слева */
    margin-top: 144px;
    /* padding: 0 8px 0; */
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 0px 2px 20px 0 rgba(155, 172, 184, 0.35);
    background-color: #24c967;
}

.menu-button {
    margin-left: 6px;
    width: 56px!important; /* почему то иногда слетает ширина кнопки, пусть будет important на всякий случай */
    height: 56px;
    border-radius: 15px;
}
.menu-button:not(.disabled):hover {
    background-color: #1eac5e;
}
.menu-button-active {
    background-color: #1eac5e;
}

.logo {
  width: 32px;
  height: 36px;
  margin: 45px 0 0 22px;
  /* background-color: #9eaab4; */
}

.disabled {
    opacity: 0.2;
}
.lng-dialog {
    position: fixed;
    z-index:99;
    left: 70px;
    top: 385px;
    width: 150px;
    height: 100px;
    background-color: #24c967;
    color: white;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}
.lng-title {
    margin: 24px 30px;
    cursor: pointer;
    font-family: Helvetica, Arial, sans-serif;
}
.lng-active {
    font-weight: bold;
}
</style>
