import Vue from 'vue';
import * as constants from '../config/constants';
import serverHelpers from './server-helpers';
import logger from './logger';

export default {
    retriveCurrentUserInfo(cb) {
        Vue.http.get(constants.SERVER_BASE_URL + '/auth/userInfo').then((response) => {
            cb(response.data);
        }, err => {
                logger.writeError('retriveCurrentUserInfo', err);
                serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
    login(loginData, cb) {
        Vue.http.post(constants.SERVER_BASE_URL + '/auth/login', loginData).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb);
        }, err => {
                logger.writeError('login', err);
                serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
    logout(cb) {
        Vue.http.post(constants.SERVER_BASE_URL + '/auth/logout').then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'isSuccess');
        }, err => {
                logger.writeError('logout', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'isSuccess');
        });
    },
    attachDelete(id, cb) {
        Vue.http.post(constants.SERVER_BASE_URL + '/attach/delete', {id: id}).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb, 'isSuccess');
        }, err => {
                logger.writeError('attachDelete', err);
                serverHelpers.analyseResponseAndCB(err, cb, 'isSuccess');
        });
    },
    retriveServerInfo(cb) {
        Vue.http.get(constants.SERVER_BASE_URL + '/ver').then((response) => {
            cb(response);
        }, err => {
                logger.writeError('retriveServerInfo', err);
                serverHelpers.analyseResponseAndCB(err, cb);
        });
    }
}