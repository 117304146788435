import serverDict from '@/api/server-dict';


let state = {
    allRoles: [],
};

const getters = {
    allRoles: state => state.allRoles
};

const actions = {
    retriveAllDicts({ dispatch }) {
        dispatch('retriveAllRoles');
    },
    retriveAllRoles({ commit }) {
        serverDict.retriveAllRoles((roles) => {
            if(roles) {
                commit('setAllRoles', roles);
            }
        });
    }
};

const mutations = {
    setAllRoles(state, allRoles) {
        state.allRoles = allRoles;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}