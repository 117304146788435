import Vue from "vue";
import VueI18n from "vue-i18n";
import { LOCALE_LOCALSTORAGE_NAME } from './config/constants';

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if(matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

function defineLocale() {
    const fromLs = localStorage.getItem(LOCALE_LOCALSTORAGE_NAME);
    const navigatorLocale = navigator.language;
    const trimmedLocale = navigatorLocale.trim().split(/-|_/)[0];
    return fromLs || trimmedLocale;
}

export default new VueI18n({
    locale: defineLocale(),
    fallbackLocale: "ru",
    messages: loadLocaleMessages()
});