<template>
    <el-dialog
        :visible.sync="isShowDialog"
        width="527px"
        destroy-on-close
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false">
        <form class="d-body" autocomplete="off">
            <div class="chpwd-dialog-title">{{$t('passChange.requiredPassCh')}}</div>
            <form-input-component :title="$t('passChange.passNew1')" v-bind:value.sync="newPass" :cw="'430px'" :isWrong="isWrongNewPass" t='password'></form-input-component>
            <form-input-component :title="$t('passChange.passNew2')" v-bind:value.sync="newPassRepeat" :cw="'430px'" :isWrong="isWrongNewPassRepeat" t='password'></form-input-component>
        </form>
        <div slot="footer" class="d-buttons">
            <el-button type="primary" class="chPwdbutton" round @click="changePassword" :loading="isLoading">{{$t('buttons.applay')}}</el-button>
        </div>
    </el-dialog>
</template>

<script>
import FormInputComponent from "@/components/FormInputComponent.vue";
import serverAccount from '../api/server-account';
import utilites from '../lib/utilites';

export default {
    data() {
        return {
            isLoading: false,
            isShowDialog: true,
            newPass: null,
            newPassRepeat: null,
            isWrongNewPass: false,
            isWrongNewPassRepeat: false
        };
    },
    methods: {
        changePassword() {
            this.isWrongNewPass = false;
            this.isWrongNewPassRepeat = false;
            if(this.newPass != this.newPassRepeat) {
                this.$store.commit('addMessageError', this.$t('passChange.changeMismatch'));
                this.isWrongNewPassRepeat = true;
            } else {
                if(!utilites.checkPassword(this.newPass)) {
                    this.isWrongNewPass = true;
                } else {
                    serverAccount.updatePassword({newPass: this.newPass, isResedRotten: true}, isSuccess => {
                        if(isSuccess) {
                            this.pass = null;
                            this.newPass = null;
                            this.newPassRepeat = null;
                            this.$store.commit('addMessageInfo', this.$t('passChange.changed'));
                            this.isShowDialog = false;
                            this.$store.commit('setGuiIsComponentChanged');
                        }
                    });
                }
            }
        }
    },
    created() {
    },
    components: {
        'form-input-component': FormInputComponent
    }
};
</script>

<style scoped>
.chpwd-dialog-title {
  height: 29px;
  font-size: 30px;
  font-weight: 300;
  text-align: left;
  margin: 0 0 46px 34px;
  color: #25272f;
}
.d-buttons {
    margin: 190px 30px 30px;
}
.chPwdbutton {
    width: 100%;
}
</style>