let initialState = {
    guiCurrentEditUser: null,
    guiCurrentEditEmpl: null,
    guiCurrentEditRole: null,
    //для отмены перехода с формы, если изменены данные и не сохранены
    guiIsComponentChanged: false,
    guiToRouteName: null
};

let state = {};
Object.assign(state, initialState);

const getters = {
    guiCurrentEditUser: state => state.guiCurrentEditUser,
    guiCurrentEditEmpl: state => state.guiCurrentEditEmpl,
    guiCurrentEditRole: state => state.guiCurrentEditRole,
    guiIsComponentChanged: state => state.guiIsComponentChanged,
    guiToRouteName: state => state.guiToRouteName
};

const mutations = {
    setGuiCurrentEditUser(state, user) {
        if(user) {
            state.guiCurrentEditUser = user;
        } else {
            state.guiCurrentEditUser = {};
        }
    },
    setGuiCurrentEditEmpl(state, user) {
        if(user) {
            state.guiCurrentEditEmpl = user;
        } else {
            state.guiCurrentEditEmpl = {};
        }
    },
    setGuiCurrentEditRole(state, role) {
        if(role) {
            state.guiCurrentEditRole = role;
        } else {
            state.guiCurrentEditRole = {};
        }
    },
    setGuiIsComponentChanged(state, isChanged) {
        state.guiIsComponentChanged = isChanged;
    },
    setGuiToRouteName(state, toRouteName) {
        state.guiToRouteName = toRouteName;
    }
    
};

export default {
    state,
    getters,
    mutations
}