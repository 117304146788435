import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import * as constants from '../config/constants';

export default {
    setup() {
        Vue.http.interceptors.push((request, next) => {
            //TODO: переделать, что бы для доступа к localStorage был отдельный метод.
            let token = window.localStorage.getItem(constants.TOKEN_LOCALSTORAGE_NAME);
            let locale = window.localStorage.getItem(constants.LOCALE_LOCALSTORAGE_NAME);
            request.headers.set('X-Auth-Token', token);
            request.headers.set('X-Locale', locale ? locale: 'ru');
            
            next((response) => {
                if(response.status === 401) {
                    // router.push('/login?url=' + response.url);
                    store.dispatch('clearCurrentUserInfo');
                    if(router && router.currentRoute) {
                        let name = router.currentRoute.name ? router.currentRoute.name : router.currentRoute.path;
                        if(name) {
                            if(!name.includes('respass') && name != 'login' && name != '/') {
                                router.push('login');
                            } 
                        }
                        // else {
                        //      router.push('login');
                        // }
                    }
                } else if(response.status === 500) {
                    this.$store.commit('addMessageError', 'Ошибка 500 при получении данных с сервера');
                }
            })
        });
    }
}