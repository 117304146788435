import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource';
import Vuex from 'vuex';
import router from './router'
import store from './store'
import i18n from "./i18n";
import elUi from "./elUi";
// import CKEditor from 'ckeditor4-vue';

Vue.use(VueResource);
Vue.use(Vuex);

import authInterceptors from './auth/interceptors';
authInterceptors.setup();

elUi.setElementUiLocale();
// Vue.use(CKEditor);

import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
Vue.use(PerfectScrollbar);

import * as filters from './filters'; // eslint-disable-line no-unused-vars

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
