import server from '../../api/server-model';

const REFRESH_INTERVAL = 1000 * 60;

let state = {
    newSurveyCreated: 0,
    newSurveyCount: 0,
    timer: null
};

const getters = {
    newSurveyCreated: state => state.newSurveyCreated,
    newSurveyCount: state => state.newSurveyCount,
}

const actions = {
    retrieveNewSurveyCount({ state, commit, rootGetters }) {
        // console.log('SURVEY STORE start')
        retrieveData();
        if(!state.timer) {
            // console.log('SURVEY STORE run timer...')
            state.timer = setInterval(retrieveData, REFRESH_INTERVAL);
        }

        function retrieveData() {
            if(rootGetters.isAdmin) {
                server.modelSpecific("surveyQue", { method: 'searchForAdmin' }, (data) => {
                    processData(data);
                });
            } else {
                server.modelSpecific("surveyQue", { method: 'searchForUser' }, (data) => {
                    processData(data);
                });
            }
        }

        function processData(data) {
            // console.log('SURVEY STORE processData...')
            if(data && data[0]) {
                commit('setNewSurveyCount', data[0].count);
            }
        }
    },
    stopRetrievingNewSurveyCount({ state }) {
        // console.log('SURVEY STORE stop')
        clearInterval(state.timer)
        state.timer = null;
    }
}

const mutations = {
    setNewSurveyCreated(state, dateOfCreation) {
        state.newSurveyCreated = dateOfCreation;
    },
    setNewSurveyCount(state, count) {
        state.newSurveyCount = count;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}