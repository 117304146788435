import server from '../../api/server-common';
import * as constants from '../../config/constants';

let initialState = {
    currentUser: null,
    currentToken: null,
    currentRoles: {},
    isAdmin: false,
    isCreator: false,
    isAuditor: false,
    isApprover: false
};

let state = {};
Object.assign(state, initialState);

const getters = {
    currentUser: state => state.currentUser,
    currentToken: state => state.currentToken,
    currentRoles: state => state.currentRoles,
    isAdmin: state => state.isAdmin
};

const actions = {
    retriveCurrentUserInfo({ commit }) {
        return new Promise((resolve) => {
            server.retriveCurrentUserInfo((user) => {
                if(user && user.account) {
                    commit('setCurrentUser', user);
                    resolve(user);
                } else {
                    resolve();
                }
            });
        });
    },
    clearCurrentUserInfo({ commit }) {
        localStorage.removeItem(constants.TOKEN_LOCALSTORAGE_NAME);
        commit('setCurrentUser');
    }
}

const mutations = {
    setCurrentUser(state, user) {
        if(!user) {
            Object.assign(state, initialState);
        } else {
            state.currentRoles = {};
            state.currentUser = user.account;
            state.currentToken = user.token;
            if (user.roles) {
                user.roles.forEach(element => {
                    state.currentRoles[element.role_id] = true;
                });
            }
            state.isAdmin = state.currentRoles[constants.ROLE_ADMIN];
        }
    }
};

export default {
    actions,
    state,
    getters,
    mutations
}