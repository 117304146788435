import * as constants from '../config/constants';

export default {
    writeDebug() {
        this.write('DEBUG', arguments);
    },
    writeError() {
        this.write('ERROR', arguments);
    },
    write(level, args) {
        if(constants.IS_LOGGER_ENABLED) {
            /* eslint-disable no-console */
            console.log(level, Date.now().valueOf())
            for(var key in args) {
                /* eslint-disable no-console */
                console.log(level, args[key]);
            }
        }
    }
}